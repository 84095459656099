var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6b39b1b2fb5e80bac0844810382f122d4cb26b85"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || "0.2")
});
